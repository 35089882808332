<template>
  <div class="model-page">
    <transition-group name="slate" mode="out-in">
      <div
        key="info"
        v-show="!isComparison"
        class="model-page__detail _flex _flex-justify__between model-page__content"
      >
        <div class="model-page__info">
          <div class="model-page__info__title _font-size__heading2 _font-weight__600">
            Tower Dimension
          </div>
          <div
            v-for="item in keys"
            :key="item.label"
            class="model-page__info__item _flex _flex-justify__between"
          >
            <span>{{ item.label }}</span>
            <span>{{ infoData[item.key] }} m</span>
          </div>
        </div>

        <div class="model-page__three _flex _flex-wrap" id="sign-model-tower__wrap">
          <div :class="['model-page__three__model', cacheEffect['sign']]" id="model-tower"></div>

          <ModelOperate
            :isFull="isFull"
            :currentEffect="cacheEffect['sign']"
            @change="handleChange($event, 'sign')"
          ></ModelOperate>
        </div>
      </div>
      <div
        key="comparison"
        v-show="isComparison"
        class="model-page__comparison model-page__content _flex _flex-justify__between _font-size__headings _font-weight__600"
      >
        <div class="model-page__three _flex _flex-wrap" id="old-model__wrap">
          <div class="model-page__three__label">25 Oct 2022</div>

          <div class="model-page__three__model" id="old-model"></div>

          <ModelOperate
            :isFull="isFull"
            :currentEffect="cacheEffect['0']"
            @change="handleChange($event, '0')"
          ></ModelOperate>
        </div>
        <div class="model-page__three _flex _flex-wrap" id="new-model__wrap">
          <div class="model-page__three__label primary">{{ currentTab.label }}</div>

          <div class="model-page__three__model" id="new-model"></div>
          <ModelOperate
            :isFull="isFull"
            :currentEffect="cacheEffect['1']"
            @change="handleChange($event, '1')"
          ></ModelOperate>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
// import screenFull from 'screenfull'
import { ROUTER_NAME } from '@/router'
import { MAIN } from '@/common/size'
import CreateThreeModel, { indexDB } from './load-three'
import { tabs, modelExtend, modelOptions, addGroup } from './props'
import ModelOperate from './components/model-operate'

/**
 * 模型实例
 * @author peng-xiao-shuai
 * @example 目前结构
 * [{ label: '19 NOV 2022', componentName: '2022-NOV-19', dirs: [文件路径]}, ...]
 * @example 完整结构
 * [
 *  {
 *    label: '19 NOV 2022',
 *    componentName: '2022-NOV-19',
 *    dirs: [文件路径],
 *    effect: 当前功能，旋转还是全屏等,
 *    modelData: 模型数据
 *  },
 *  ...
 * ]
 */
const Tabs = JSON.parse(JSON.stringify(tabs))

// 单模型
let singleModel
// 双模型比较
const compareModel = []
export default {
  components: {
    ModelOperate
  },
  data() {
    return {
      keys: [
        {
          label: 'Overall Height',
          key: 'height'
        },
        {
          label: 'Base Width',
          key: 'width'
        },
        {
          label: 'Base Depth',
          key: 'depth'
        }
      ],

      infoData: {
        width: '1.65',
        height: '15.25',
        depth: '1.65'
      },

      componentName: Tabs[0].componentName,
      isComparison: false,

      singleModelWrapDom: null,
      compareModelWrapDom: [],

      cacheEffect: {
        sign: '',
        0: '',
        1: ''
      },
      isFull: false
    }
  },
  computed: {
    tabsIndex() {
      return Tabs.findIndex((item) => item.componentName === this.componentName)
    },
    // 主要用于页面上调用Tabs
    currentTab() {
      console.log(this.componentName)
      return Tabs.find((item) => item.componentName === this.componentName)
    }
  },
  mounted() {
    const height = this.getDomHeight()

    this.$bus.$on('model-change', (bol) => {
      this.isComparison = bol
      // 停止模型和启动模型
      if (bol) {
        singleModel.stopAnimate()
        compareModel.forEach((model) => {
          model.animate()
        })
      } else {
        singleModel.animate()
        compareModel.forEach((model) => {
          model.stopAnimate()
        })
      }
    })

    this.$bus.$on('export-model', () => {
      singleModel.exportModel(`${this.currentTab.label}`)
    })

    /** -------- 切换时 --------- */
    this.$bus.$on('route-nav', (component) => {
      if (this.$route.name !== ROUTER_NAME.MODEL) return
      this.componentName = component.componentName

      if (this.isComparison) {
        // 第一次点击Model Comparison 进入if 初始化
        if (!compareModel.length) {
          // 统计是否都存在 modelData 数据
          let countModelData = 0
          // 模型对比 初始辅助数组
          ;[
            {
              id: 'old-model',
              tabs: Tabs[0]
            },
            {
              id: 'new-model',
              tabs: Tabs[this.tabsIndex === 0 ? 1 : this.tabsIndex]
            }
          ].forEach((item, index) => {
            // new 一个模型
            compareModel.push(
              new CreateThreeModel(
                modelOptions.apply(this, [
                  {
                    id: item.id,
                    referencePoint: item.tabs.referencePoint,
                    oldReferencePoint: item.tabs.oldReferencePoint,
                    height
                  }
                ])
              )
            )
            // 初始化模型
            compareModel[index].init()
            // 手动触发开始创建模型
            compareModel[index].options.loadingCompleted.apply(compareModel[index])
            // 添加全屏dom
            this.compareModelWrapDom[index] = document.getElementById(`${item.id}__wrap`)

            compareModel[index].createLoading()
            // 判断是否有 model 数据
            if (item.tabs.modelData) {
              /**
               * 该if下情况不一样，属于数据已经加载好了，只需要控制渲染，而且还需要保证UI切换时流畅，故此
               * 添加 setTimeout 演示渲染，延时时间为 UI 动画时间
               */
              // 模型渲染中提示 也可以写成 compareModel[index].loading.text = 'xxxxx'
              compareModel[index].options.loadingCompleted.apply(compareModel[index])
              setTimeout(() => {
                compareModel[index].group.add(item.tabs.modelData.clone())
              }, 300) // 这里的 300 是动画时间，动画时间结束后执行渲染
              countModelData += 1

              // 2 是初始辅助数组长度
              if (countModelData === 2) {
                // 手动触发渲染完成, 使用setTimeout 创建宏任务，等待渲染宏任务执行完成
                setTimeout(() => {
                  compareModel[index].loading.close()
                }, 301) // 这里时间只要大于300(上面定义的时间) 就行，小于300 loading 不会显示，大于300之后loading.close()会在渲染后执行
              }
            } else {
              // 读取数据库数据
              compareModel[index]
                .getIndexedDBData(item.tabs.dirs, (modelData) => {
                  Tabs[this.tabsIndex].modelData = modelData.clone()
                })
                .catch(() => {
                  compareModel[index].options.startCreateModel.apply(compareModel[index])
                  // 循环渲染创建模型
                  compareModel[index].createPLYModel(
                    item.tabs.dirs,
                    this.currentTab.componentName,
                    (modelData) => {
                      Tabs[this.tabsIndex].modelData = modelData.clone()
                    }
                  )
                })
            }
            compareModel[index].modelGroupName = item.tabs.componentName

            // 绑定鼠标事件等其他
            modelExtend.apply(this, [compareModel[index], this.cacheEffect, index])
          })
        } else {
          addGroup.apply(this, [compareModel[1], Tabs, '1'])
        }
      } else {
        addGroup.apply(this, [singleModel, Tabs, 'sign'])
      }
    })

    this.$bus.$emit('mounted', true)

    // 初始化
    singleModel = new CreateThreeModel(
      modelOptions.apply(this, [
        {
          id: 'model-tower',
          referencePoint: Tabs[0].referencePoint,
          height,
          width: document.getElementById('model-tower').clientWidth
        }
      ])
    )
    singleModel.init()
    singleModel.modelGroupName = this.currentTab.componentName

    // 初始化IndexedDB
    indexDB.init(() => {
      // 读取数据库数据
      singleModel
        .getIndexedDBData(this.currentTab.dirs, (modelData) => {
          // 这里使用 clone 克隆，进行深拷贝
          Tabs[this.tabsIndex].modelData = modelData.clone()
        })
        .catch(() => {
          // 循环渲染创建模型
          singleModel.createPLYModel(
            this.currentTab.dirs,
            this.currentTab.componentName,
            (modelData) => {
              // 这里使用 clone 克隆，进行深拷贝
              Tabs[this.tabsIndex].modelData = modelData.clone()
            }
          )
        })
    })

    this.singleModelWrapDom = document.getElementById('sign-model-tower__wrap')
    // singleModel.loading.close()
    // 绑定鼠标事件等其他
    modelExtend.apply(this, [singleModel, this.cacheEffect, 'sign'])
  },
  activated() {
    this.$bus.$emit('sync-data', { isComparison: this.isComparison, tabsIndex: this.tabsIndex })
    // 开启渲染
    if (this.isComparison) {
      compareModel.forEach((model) => {
        model.animate()
      })
    } else {
      singleModel.animate()
    }
  },
  deactivated() {
    // 模型停止
    if (this.isComparison) {
      compareModel.forEach((model) => {
        model.stopAnimate()
      })
    } else {
      singleModel.stopAnimate()
    }
  },
  methods: {
    getDomHeight() {
      const dom = document.getElementsByClassName('model-page__three__model')[0]
      // 100 / 20 model-page__three 底部内边距
      return (
        // eslint-disable-next-line no-underscore-dangle
        window.innerHeight - dom.getBoundingClientRect().top - MAIN.padding - 0.2 * window._fontSize
      )
    },
    /**
     * 图标点击事件
     * @author peng-xiao-shuai
     * @param item 当前点击项
     * @param {string|number} type 单塔传递 sign 多塔传递 number
     */
    handleChange(item, type) {
      // 区分单个模型还是多个模型
      const Model = type === 'sign' ? singleModel : compareModel[type]
      // const modeWrapDom = type === 'sign' ? this.singleModelWrapDom : this.compareModelWrapDom[type]
      // 当前tab 由于this.currentTab 在模型对比的时候一直是最后一个模型的数据
      const cTab = type === 'sign' ? Tabs[0] : Tabs[type]
      // set 当前功能
      this.cacheEffect[type] = item.effect

      // 拷贝模型
      Model.renderer.domElement.style.cursor = 'default'

      Model.controls.enableZoom = false
      Model.controls.enableRotate = false

      // 功能判断
      switch (this.cacheEffect[type]) {
        case 'reset':
          Model.controls.enabled = false
          Model.renderer.domElement.style.cursor = 'move'
          break
        case 'rotate':
          Model.controls.enabled = true
          Model.controls.enableRotate = true
          Model.renderer.domElement.style.cursor = 'grab'
          break
        case 'full': {
          Model.controls.enabled = true
          Model.controls.enableZoom = true
          // if (!modeWrapDom) return
          // if (screenFull.isFullscreen) {
          //   screenFull.exit()
          //   Model.reset()
          // } else {
          //   screenFull.toggle(modeWrapDom)
          //   setTimeout(() => {
          //     Model.renderer.setSize(
          //       Model.container.clientHeight * Model.camera.aspect,
          //       Model.container.clientHeight
          //     )
          //     Model.camera.updateProjectionMatrix()
          //   }, 240)
          // }
          break
        }
        case 'ruler':
          Model.renderer.domElement.style.cursor = 'crosshair'
          if (
            !Model.group
              .getObjectByName(Model.modelGroupName)
              .getObjectByProperty('name', 'referencePoint')
          ) {
            Model.createReferencePoint(Model.modelGroupName, {
              referencePoint: cTab.referencePoint
            })
          }
          break
        default:
      }

      Model.effect = this.cacheEffect[type]
    }
  }
}
</script>

<style lang="scss" scoped>
.model-page {
  &__content {
    width: var(--main-width);
    position: relative;

    > div {
      padding: 20px;
      border-radius: 10px;
      background: var(--color-white);
    }
  }

  &__detail {
    .model-page__info {
      width: 270px;
      &__title {
        margin-bottom: 30px;
      }

      &__item {
        font-size: 16px;
        & + .model-page__info__item {
          margin-top: 25px;
        }
      }
    }
    .model-page__three {
      width: calc(100% - 270px - 20px);
    }
  }

  &__comparison {
    .model-page__three {
      width: 49%;
      position: relative;

      &__label {
        position: absolute;
        top: 22px;
        width: 150px;
        height: 47px;
        line-height: 47px;
        padding: 0px 10px;
        color: var(--color-white);
        border-radius: 50px;
        text-align: center;
        background: #1f97f5;
        margin: 0 auto;
        &.primary {
          background: var(--color-primary);
        }
      }
    }
  }

  .model-page__three {
    justify-content: center;

    &__model {
      // position: fixed;
      // left: 0;
      // top: 0;
      width: 100%;
      min-height: 690px;
      height: calc(100% - 67.5px - 20px);
      ::v-deep canvas {
        transition: all 0.3s;
      }
    }

    .rotate ::v-deep canvas {
      cursor: grab;
    }

    .rules ::v-deep canvas {
      cursor: crosshair;
    }
  }

  ::v-deep canvas {
    margin: 0 auto;
  }
}
</style>
